import * as React from 'react';
import axios from 'axios';
import Layout from '../components/Layout';
import styled from 'styled-components';
import { Button, Input, Select } from '@vechaiui/react';
import { useState } from 'react';

const Rate = () => {
    const [assets, setAssets] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    let assetList = {};

    React.useEffect(async () => {
        try {
            const req = await axios.get('http://localhost:7777/assets')
            if (req.status === 200) {
                setAssets(req.data);
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }, []);

    const handleSave = async (type, name) => {
        const asset = assetList[type][name];
        if (!asset) return;

        console.log('asset', asset);

        try {
            const req = await axios.post('http://localhost:7777/assets/modify', asset)
            if (req.status === 200) {
                console.log('SAVED');
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }

    const handleChange = (e, type, name) => {
        if (!assetList[type]) {
            assetList[type] = {};
        }
        if (!assetList[type][name]) {
            assetList[type][name] = { fileType: type, filename: name, faction: '', rarity: '', name: '', roomType: 'training_grounds' };
        }
        assetList[type][name][e.target.name] = e.target.value;

        console.log(assetList);
    }

    return (
        <Layout>
            {loading ? (
                <p>loading...</p>
            ) : (
                <div className='asset-list'>
                    {assets.map(asset => (
                        <AssetContainer key={asset.id}>
                            <h1>{asset.name}</h1>
                            <div className="assets-list">
                                {asset.assets.map(mapItem => (
                                    <div key={mapItem} className="item">
                                        <img src={`/assetTypes/training_grounds/${asset.filename}/${mapItem}`} alt="" />
                                        <Input name='name' onChange={e => handleChange(e, asset.filename, mapItem)} placeholder='Asset Name' />
                                        <Select name='rarity' onChange={e => handleChange(e, asset.filename, mapItem)}>
                                            <option value="COMMON">COMMON</option>
                                            <option value="UNCOMMON">UNCOMMON</option>
                                            <option value="MODERATE">MODERATE</option>
                                            <option value="RARE">RARE</option>
                                            <option value="LEGENDARY">LEGENDARY</option>
                                        </Select>
                                        <Select name='faction' onChange={e => handleChange(e, asset.filename, mapItem)}>
                                            <option value="">NONE</option>
                                            <option value="DRAGONRIDERS">DRAGONRIDERS</option>
                                            <option value="ETHER_GUARDIANS">ETHER_GUARDIANS</option>
                                            <option value="IMMORTAL_COUNCIL">IMMORTAL_COUNCIL</option>
                                        </Select>
                                        <Button type='button' onClick={() => handleSave(asset.filename, mapItem)}>Save</Button>
                                    </div>
                                ))}
                            </div>
                        </AssetContainer>
                    ))}
                </div>
            )}
        </Layout>
    )
}

export default Rate;

const AssetContainer = styled.div`
    .assets-list {
        display: grid;
        grid-template-columns: repeat(auto-fill, 300px);

        .item {
            width: 250px;
            input {
                margin-bottom: 10px;
            }

            select {
                margin-bottom: 10px;
            }
        }
    }
`